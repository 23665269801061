import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TextField.css';

class TextField extends Component {
  static propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    value: "",
    error: false,
    errorMessage: "",
    errorClassName: "",
    size: "md",
    type: "input",
    name: "",
    fontStyle: "primary",
    className: null,
    errorStyle: {},
    style: {},
    required: false,
    optional: false
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      active: false
    };
  }

  onChange(event) {
    let { onChange, integer } = this.props;
    console.log("uh ha ha ha ha ha", integer)
    if (integer) {
      let { value } = event.target
      let OK = /[0-9+$]/.test(value)
      if (!OK && value.length > 0) return;
    }

    if (this.props.onChange !== null) {
        this.props.onChange(event);
    }
  }

  onBlur(event) {
    //let { onBlur, validator } = this.props;

    let { onBlur } = this.props;

    if (onBlur != null) {
        onBlur(event);
    }

    /*if (!validator(this.props.value)) {
      this.setState({
        error: true
      })
    } else {
      if (this.state.error) {
        this.setState({
          error: false
        })
      }
    }*/
  }

  getLabel() {
    let className = "";
    let { label, fontStyle, required, optional } = this.props;
    if (label == null || label == "") {
      return;
    }

    if (fontStyle === "primary") {
      className = "body-copy-primary";
    } else if (fontStyle === "secondary") {
      className = "body-copy-secondary";
    } else if (fontStyle === "tertiary") {
      className = "body-copy-bold-tertiary";//"body-copy-tertiary";
    } else if (fontStyle === "header-3-tertiary") {
      className = "header-3-tertiary";//"body-copy-tertiary";
    }

    return (
      <label className={className}>{required ? "*" : ""}{label}{optional ? " (Optional)" : ""}</label>
    );
  }

  getTextArea() {
    let { size, name, type, value, placeholder } = this.props;

    let className = ["textarea"];
    className = (size == "full" ? className.concat(["textarea-full"]) : className);
    className = (size == "lg" ? className.concat(["textarea-lg"]) : className);
    className = (size == "md" ? className.concat(["textarea-md"]) : className);
    className = (size == "sm" ? className.concat(["textarea-sm"]) : className);
    className = (size == "half" ? className.concat(["textarea-half"]) : className);

    return (
      <textarea
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        className={className.join(" ")}
        onBlur={this.onBlur.bind(this)}
        onChange={this.onChange.bind(this)}/>
      )
  }

  getInput() {
    let className = ["textfield"];

    let { style, leftRounded, size, name, type, value, placeholder } = this.props;

    className = (leftRounded === true ? className.concat(["textfield-left-rounded"]) : className);
    className = (size === "full" ? className.concat(["textfield-full"]) : className);
    className = (size == "lg" ? className.concat(["textfield-lg"]) : className);
    className = (size === "md" ? className.concat(["textfield-md"]) : className);
    className = (size === "sm" ? className.concat(["textfield-sm"]) : className);
    className = (size === "half" ? className.concat(["textfield-half"]) : className);

    return (
      <input
        style={style}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        className={className.join(" ")}
        onBlur={this.onBlur.bind(this)}
        onChange={this.onChange.bind(this)}/>
    );
  }

  getBase() {
    if (this.props.isTextArea) {
      return this.getTextArea();
    }
    return this.getInput();
  }

  render() {
    let { size, style, className, error, errorMessage, errorClassName, errorStyle } = this.props;

    let containerClassName = [];

    containerClassName = (size == "full" ? containerClassName.concat(["textfield-container-full"]) : containerClassName);
    containerClassName = (size == "lg" ? containerClassName.concat(["textfield-container-lg"]) : containerClassName);
    containerClassName = (size == "md" ? containerClassName.concat(["textfield-container-md"]) : containerClassName);
    containerClassName = (size == "sm" ? containerClassName.concat(["textfield-container-sm"]) : containerClassName);
    containerClassName = (size == "half" ? containerClassName.concat(["textfield-container-half"]) : containerClassName);

    containerClassName = (className !== null ? containerClassName.concat([className]) : containerClassName);

    let containerStyle = (style === null ? {} : style);

    return (
      <div style={containerStyle} className={containerClassName.join(" ")}>
        {this.getLabel()}
        {this.getBase()}
        <div className={error ? "textfield-error-container" : "textfield-hidden" }>
          <div style={errorStyle} className={`${errorClassName} body-copy-error-xs-small`}>{errorMessage}</div>
        </div>
      </div>
    );
  }
}

export default TextField;
