export const ActionTypes = {
    POST_PORTFOLIO_PLACEMENT_PREFERENCES: 'POST_PORTFOLIO_PLACEMENT_PREFERENCES',
    POST_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS: 'POST_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS',
    POST_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE: 'POST_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE',
    GET_PORTFOLIO_PLACEMENT_PREFERENCES: 'GET_PORTFOLIO_PLACEMENT_PREFERENCES',
    GET_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS: 'GET_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS',
    GET_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE: 'GET_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE',
    PUT_PORTFOLIO_PLACEMENT_PREFERENCES: 'PUT_PORTFOLIO_PLACEMENT_PREFERENCES',
    PUT_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS: 'PUT_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS',
    PUT_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE: 'PUT_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE',
};

export const getPortfolioPlacementPreferences = (portfolio_id) => ({
  type: ActionTypes.GET_PORTFOLIO_PLACEMENT_PREFERENCES,
  payload: {
    portfolio_id
  }
})

export const postPortfolioPlacementPreferences = (portfolio_placement_preferences) => ({
  type: ActionTypes.POST_PORTFOLIO_PLACEMENT_PREFERENCES,
  payload: {
    portfolio_placement_preferences
  }
});


export const putPortfolioPlacementPreferences = (portfolio_placement_preferences) => ({
  type: ActionTypes.PUT_PORTFOLIO_PLACEMENT_PREFERENCES,
  payload: {
    portfolio_placement_preferences
  }
});
