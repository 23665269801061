import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Tab from 'common/Tab';
import Pill from 'components/Pill';
import TabGroup from 'common/TabGroup';
import Detail from 'common/Detail';
import List from 'common/list.component';
import { generateDateTimeFormat } from 'common/helpers/date';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { getPortfolio } from 'services/portfolios/portfolios.actions';
import { getPortfolioPlacementPreferences } from 'services/portfolioplacementpreferences/portfolioplacementpreferences.actions';
import { getPortfolioObjective } from 'services/portfolioobjectives/portfolioobjectives.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './portfolio.css';

const tabs = [
  { label: "Overview" },
/*P  { label: "Placements" },
  { label: "Audience" },
  { label: "Recommendations" }*/
]

class Portfolio extends Component {
  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        overview: true
      },
    }
  }

  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.getPortfolio(id);
    this.props.getPortfolioPlacementPreferences(id);
    this.props.getPortfolioObjective(id);
  }

  //Similar code in eventreview.screen.js, event.screen.js
  setAudienceProfile(audience_attributes) {
    let buffer = [];
    let items = [];
    let index = 0;
    for (let key in audience_attributes) {
      let attributes = audience_attributes[key];
      let label = `${key}s`;

      if (key === "Age Range") {
        items = attributes.map((attribute) => <Pill isCloseVisible={false} label={attribute.data} />);
      } else if (key === "Location") {
        items = attributes.map((attribute) => <Pill isCloseVisible={false} label={attribute.data.description} />);
      } else if (key === "Occupation") {
        items = attributes.map((attribute) => <Pill isCloseVisible={false} label={attribute.data} />);
      } else if (key === "Industry") {
        label = 'Industries';
        items = attributes.map((attribute) => <Pill isCloseVisible={false} label={attribute.data} />);
      }

      buffer.push(
        <div key={index}>
          <div style={{marginBottom: '10px'}} className="body-copy-bold-tertiary">{label}</div>
          <div className="d-flex flex-wrap event-details-pill-wrapper">{items}</div>
        </div>
      )
      index++;
    }

    return (<div className="event-details-audience-attributes">{buffer}</div>);
  }


  setDesiredPlacements(portfolio_desired_placements) {
    let placements = portfolio_desired_placements.map((placement)=> placement.name);
    return (<List items={placements} />);
  }

  setAudienceProfileTab(audience_attributes) {
    if (!this.state.tabs.audience) return (null);

    return (
      <div className="event-details-panel event-details-audience-tab">
        {this.setAudienceProfile(audience_attributes)}
      </div>
    )
  }


  activate(current) {
    let { tabs } = this.state;

    let update = {};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab] = true;
      } else {
        update[tab] = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  setRecommendationsTab(recommendations) {
    if (!this.state.tabs.recommendations) return null;

    if (!recommendations) {
      return (null);
    }

    if (recommendations.length === 0) {
      return (null);
    }

    return (
      <div style={{marginTop: '25px'}} className="portfolioreview-details">
        <div className="portfolio-recommendations-container">
          {
            recommendations.map((item) => {
              return (
                <div className="portfolio-recommendation-row d-flex flex-row body-copy-tertiary">
                  <div className="col-6">Event: <a href={`/event/${item.event_id}`} target="_blank" rel="noopener noreferrer">{item.event_name}</a></div>
                  <div className="col-6">Score: {item.score.toFixed(2)}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  setOverviewTab(portfolio) {
    if (!this.state.tabs.overview) return null;
    let { name, lower_bound, upper_bound } = portfolio;
    let { preferences, objective } = this.props;
    let {
      portfolio_objective_type
    } = objective[0];

    let {
      type,
      marketing_funnel_stage
    } = portfolio_objective_type;

    let {
      stage
    } = marketing_funnel_stage;



    return (
      <div>
        <div className="portfolioreview-details">
          <Eyebrow label="Portfolio"/>
          <div className="body-copy-tertiary">
            <div>Name: {name}</div>
            <div>Objective: {type} ({stage})</div>
            <div>Budget: {generateCurrencyFormat(lower_bound)} - {generateCurrencyFormat(upper_bound)}</div>
          </div>
          <div>
        </div>
        <div className="portfolioreview-details">
          <Eyebrow label="Portfolio Preferences"/>

            <div className="body-copy-tertiary">
            {
              this.props.preferences.map((preference) => {
                let { placement } = preference;

                return <div>{placement.placement_type.type}: {placement.placement}</div>
              })
            }
            </div>
            {/*<div>Budget: {generateCurrencyFormat(budget)}</div>
            <div>Start Date: {generateDateTimeFormat(new Date(startDate))}</div>
            <div>End Date: {generateDateTimeFormat(new Date(endDate))}</div>*/}
          </div>


        </div>
      </div>
    )
  }

  setDesiredPlacementsTab(portfolio_desired_placements) {
    if (!this.state.tabs.placements) return null;

    return(
      <div className="portfolioreview-details">
        <Eyebrow label="Desired Placements" />
        <div className="body-copy-tertiary">
          {this.setDesiredPlacements(portfolio_desired_placements)}
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.portfolio || !this.props.objective || !this.props.preferences) {
      return (null);
    }

    //let { portfolio, portfolio_desired_placements, audience, recommendations } = this.props.portfolio;

    return (
      <Wrapper className="container portfolioreveiw-section">
        <div style={{marginBottom: "25px"}} className="d-flex flex-row justify-content-between">
          <Detail justification="start" header="Portfolio" subHeader="View your portfolio information below" />
        </div>
        <div>
          <TabGroup>
            {
              tabs.map(({ label, icon }, index)=>{
                return (
                  <Tab
                    type="bold"
                    isCompleteVisible={false}
                    icon={icon}
                    key={index}
                    label={label}
                    selected={this.state.tabs[label.toLowerCase()]}
                    onClick={this.activate.bind(this, label.toLowerCase())} />
                  )
              })
            }
          </TabGroup>
        </div>
        {/*this.setAudienceProfileTab(audience)*/}
        {/*this.setRecommendationsTab(recommendations)*/}
        {this.setOverviewTab(this.props.portfolio)}
        {/*this.setDesiredPlacementsTab(portfolio_desired_placements)*/}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    portfolio: state.portfolios.currentPortfolio,
    objective: state.portfolioobjectives.objective,
    preferences: state.portfolioplacementpreferences.preferences
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getPortfolio,
      getPortfolioPlacementPreferences,
      getPortfolioObjective
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
