import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './portfolioobjectives.actions';
import { post, getDropwizard } from '../Api'

const basePath = "portfolio-objectives"

export const postPortfolioObjective = function* (action) {
  const { payload: { portfolio_id, objective, portfolio_name } } = action;
  try {
    const response = yield call(post, basePath, {
      portfolio_id,
      objective,
      portfolio_name
    });
    const data = yield response.json();

    yield put({ type: ActionTypes.POST_PORTFOLIO_OBJECTIVE_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_PORTFOLIO_OBJECTIVE_FAILURE, error });
  }
}

export const getPortfolioObjective = function* (action) {
  let { portfolio_id } = action.payload;
  try {
    const response = yield call(getDropwizard, `${basePath}?portfolio_id=${portfolio_id}`);
    yield put({ type: ActionTypes.GET_PORTFOLIO_OBJECTIVE_SUCCESS, objective: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_PORTFOLIO_OBJECTIVE_FAILURE, error });
  }
}

function* portfolioobjectives() {
  yield all([
    takeLatest(ActionTypes.POST_PORTFOLIO_OBJECTIVE, postPortfolioObjective),
    takeLatest(ActionTypes.GET_PORTFOLIO_OBJECTIVE, getPortfolioObjective),
  ]);
}

export default portfolioobjectives;
