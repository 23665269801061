import React, { Component } from 'react';
import Button from 'components/new/Button';
import './PaginatedButtonGroup.css';

class PaginatedButtonGroup extends Component {
  static defaultProps = {
    size: 10
  }
  getButtons() {
    let { offset, total, next, back, action } = this.props;
    let page = offset/10 + 1;
    let lastPage = Math.ceil(total/10);
    let buffer = [];

    if (offset !== 0) {
      buffer.push(<Button
        type="primary"
        label="Back"
        onClick={back.bind(this)} />);
    }


    for (let i = page; i < page + 4 && i < lastPage; i++) {
      console.log(this.props.size);
      buffer.push(<Button
        type="primary"
        label={i + ""}
        size="button-group"
        onClick={action.bind(this, i*this.props.size)} />);
    }

    if (page < lastPage) {
      buffer.push(<Button
        type="primary"
        label="Next"
        onClick={next.bind(this)} />)
    }
    return buffer;
  }

  render() {
    return (
      <div className="d-flex paginated-button-group-container flex-wrap">
        {this.getButtons()}
      </div>
    );
  }
}

export default PaginatedButtonGroup;
