export const ActionTypes = {
    SEARCH_CONFERENCE_PROSPECTUSES: 'SEARCH_CONFERENCE_PROSPECTUSES',
    SEARCH_CONFERENCE_PROSPECTUSES_SUCCESS: 'SEARCH_CONFERENCE_PROSPECTUSES_SUCCESS',
    SEARCH_CONFERENCE_PROSPECTUSES_FAILURE: 'SEARCH_CONFERENCE_PROSPECTUSES_FAILURE'
  }

export const searchConferenceProspectuses = (term, offset) => ({
    type: ActionTypes.SEARCH_CONFERENCE_PROSPECTUSES,
    payload: {
      term,
      offset
    }
});
