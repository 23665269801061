import React, { Component } from 'react';
import './Button.css';

class Button extends Component {
  static defaultProps = {
    buttonType: "button",
    style: {},
    className: null,
    disabled: false,
    icon: null,
    type: "primary",
    iconStyles: {},
    stopPropagation: false,
    iconOrientation: ""
  }

  getSize(className) {
    if (this.props.size === null) {
      return className;
    }

    if (this.props.size === 'lg') {
      return className.concat(["button-lg"]);
    } else if (this.props.size === 'md') {
      return className.concat(["button-md"]);
    } else if (this.props.size === 'sm') {
      return className.concat(["button-sm"]);
    } else if (this.props.size === 'full') {
     return className.concat(["button-full"]);
   } else if (this.props.size === 'xs') {
     return className.concat(["button-xs"]);
   } else if (this.props.size === 'button-group') {
     return className.concat(["button-button-group"]);
   }
    return className;
  }

  getIcon() {
    if (this.props.icon === null) {
      return null;
    }
    let height = this.props.iconStyles.height || '15px';

    let style = { height: '22px', objectFit: 'contain'};//let style = { height: '100%', objectFit: 'contain'};

    if (this.props.label) {
      if (this.props.iconOrientation === 'reverse') {
        style = {marginLeft: '15px', height };
      } else {
        style = {marginRight: '15px', height };
      }
    } else {
      style = {
        height
      }
    }

    return (
      <img style={style} src={this.props.icon}/>
    );
  }

  onClick(e) {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
    this.props.onClick()
  }

  render() {
    let { buttonType, type, disabled, label, rightRounded, style, size } = this.props;
    let className = ["d-flex", "flex-row", "align-items-center", "justify-content-center"];

    className = (rightRounded === true ? className.concat(["button-right-rounded"]) : className);
    className = (this.props.className !== null ? className.concat([this.props.className]) : className);
    className = this.getSize(className);

    if (type === "primary") {
      if (size === 'xs') {
        className = className.concat(["new-button", "button-primary", "body-copy-primary"]);
      } else {
        className = className.concat(["new-button", "button-primary", "body-copy-primary"]);
      }
    } else if (type === "primary-circle") {
      className = className.concat(["button-primary-circle", "body-copy-primary", "button-primary"]);
    } else if (type === "primary-border") {
      if (size === 'xs') {
        className = className.concat(["new-button", "button-primary-border", "body-copy-primary"]);
      } else {
        className = className.concat(["new-button", "button-primary-border", "body-copy-primary"]);
      }
    } else if (type === "secondary"){
      if (size === 'xs') {
        className = className.concat(["new-button", "button-secondary"]);
      } else {
        className = className.concat(["new-button", "button-secondary"]);
      }
    } else if (type === "secondary-circle") {
      className = className.concat(["button-secondary-circle", "body-copy-primary", "button-secondary"]);
    } else if (type === "tertiary"){
      if (size === 'xs') {
        className = className.concat(["new-button", "button-tertiary", "body-copy-white-small"]);
      } else {
        className = className.concat(["new-button", "button-tertiary", "body-copy-white"]);
      }
    } else if (type === "quarternary"){
      if (size === 'xs') {
        className = className.concat(["new-button", "button-quarternary", "body-copy-secondary-small"]);
      } else {
        className = className.concat(["new-button", "button-quarternary", "body-copy-secondary"]);
      }
    } else if (type === "quintary"){
      if (size === 'xs') {
        className = className.concat(["new-button", "button-quintary", "body-copy-quintary-small"]);
      } else {
        className = className.concat(["new-button", "button-quintary", "body-copy-quintary"]);
      }
    } else if (type === "senary"){
      if (size === 'xs') {
        className = className.concat(["new-button", "button-senary", "body-copy-senary-small"]);
      } else {
        className = className.concat(["new-button", "button-senary", "body-copy-senary"]);
      }
    }  else if (type === "gray"){
      if (size === 'xs') {
        className = className.concat(["new-button", "button-gray", "body-copy-tertiary-small"]);
      } else {
        className = className.concat(["new-button", "button-gray", "body-copy-tertiary"]);
      }
    } else if (type === "transparent"){
      className = className.concat(["button-transparent", "body-copy-tertiary"]);
    }

    if (this.props.icon && this.props.iconOrientation === 'reverse') {
      className = className.concat(["flex-row-reverse"])
    }

    return (
      <button
        disabled={disabled}
        type={buttonType}
        style={style}
        className={className.join(" ")}
        onClick={this.onClick.bind(this)}
        >{this.getIcon()}{label ? label.toUpperCase() : ""}</button>
    );
  }
}

export default Button;
