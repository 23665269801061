import React, { Component } from 'react';
import EventDetail from './EventDetail';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import Tab from 'common/Tab';
import Pill from 'components/Pill';
import TabGroup from 'common/TabGroup';
import Accordion from 'components/Accordion';
import Divider from 'common/Divider.component';
import EventGallery from 'screens/event/components/eventgallery.component';
import EventSidePanel from 'screens/event/components/eventsidepanel.component';
import EventAudienceTab from 'screens/event/components/eventaudiencetab.component';
import EventOverviewTab from 'screens/event/components/eventoverviewtab.component';
import ButtonGroup from 'components/ButtonGroup';
import Modal from 'components/Modal';
import TextField from 'components/TextField';
import { notification } from 'antd';
import { Menu, Dropdown } from 'antd';
import { generateDateFormat } from 'common/helpers/date';
import { bindActionCreators } from 'redux';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { getEvent, postEventDecision } from 'services/events/events.actions';
import { connect } from 'react-redux';
import DownArrow from 'assets/down-arrow-white-small.svg';
import NoEventLogo from 'assets/noLogo.png';
import './review.css';

let labels = [
  {
    label: "Overview",
    active: true,
  },
  {
    label: "Audience",
    active: true,
  },
  {
    label: "Sponsorships",
    active: true
  }
]

class AdminEventReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        overview: {
          active: true,
          label: "Overview"
        },
        audience: {
          active: false,
          label: "Audience"
        },
        sponsorships: {
          active: false,
          label: "Sponsorships"
        }
      },
      isGalleryModalOpen: false,
      selectedPackage: null,
      isEventRequestUpdateModalVisible: false,
      isRejectEventModalVisible: false,
      message: {
        value: "",
        error: false
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.decision.event_id !== this.props.decision.event_id) {
      notification.success({
        message: 'Success!',
        description: `This event has been approved.`
      });
    }
  }

  setModalVisible(isVisible) {
    this.setState({
      isGalleryModalOpen: isVisible
    })
  }

  setCustomizeModalVisible(isVisible) {
    this.setState({
      isCustomizePackageModalVisible: isVisible
    })
  }

  onClickSubmitRequestEventUpdate() {

  }

  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.getEvent(null, id);
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  activate(current) {
    let { tabs } = this.state;
    let update = {...tabs};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab].active = true;
      } else {
        update[tab].active = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  getImages(assets) {
    let { event } = this.props;

    if (!event) {
      return;
    }

    assets = event.assets;

    let images = assets.filter((asset) => {
      return asset.asset_type_id == 1
    }).map(({url}) => {
      return {
        src: url
      }
    });
    return images;
  }

  getLogo(assets) {
    let { event } = this.props;

    if (!event) {
      return;
    }

    assets = event.assets;

    let images = assets.filter((asset) => {
      return asset.asset_type_id == 3
    }).map(({url}) => {
      return {
        src: url
      }
    });
    return images;
  }

  setAudienceTab() {
    if (!this.state.tabs.audience.active) return (null);
    let { audienceAttributes, eventGender, eventAttendances } = this.props.event;
    return (
      <EventAudienceTab
        expected_attendees={this.props.event.event.expected_attendees}
        audience_attributes={audienceAttributes}
        eventGender={eventGender}
        eventAttendances={eventAttendances}
      />
    )
  }

  setOverviewTab(description) {
    if (!this.state.tabs.overview.active) return (null);

    return (
      <EventOverviewTab assets={this.props.event.assets} event={this.props.event.event} />
    )
  }

  toggleRequestEventUpdateModal(isVisible) {
    this.setState({
      isEventRequestUpdateModalVisible: isVisible
    })
  }

  toggleRejectEventModal(isVisible) {
    this.setState({
      isRejectEventModalVisible: isVisible
    })
  }

  setPackagesTab(packages) {
    if (!this.state.tabs.sponsorships.active) return (null);
    if (packages === null) return (null);
    let panels = [];

    packages.forEach((item, index) => {
      let { name, cost, limit, placements, description } = item;

      let content = () => {
        return (
          <div key={index} className="event-details-packages-content">
            <EventDetail label="Description" value={description} />
            {placements ? <EventDetail label="Package Contents" value={(placements.length === 0 ? "None" : placements.map((placement) => placement.placement.placement).join(", "))} /> : null}
            <EventDetail label="# of Available Packages" value={limit} />
          </div>
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row">
            <div>
              <div className='body-copy-bold-brand'>{name}</div>
              <div className="body-copy-tertiary">{generateCurrencyFormat(cost)}</div>
            </div>
          </div>
        ),
        content
      }

      panels.push(panel);
    });

    return (
      <div className="event-details-panel">
        <Accordion panels={panels} />
      </div>
    )
  }

  buyPackage(selectedPackage) {
    this.setState({
      selectedPackage,
      isCustomizePackageModalVisible: true
    })
  }

  getTabs() {
    let buffer = [];
    let index = 0;

    for (let tab in this.state.tabs) {

      let { label, active, hidden } = this.state.tabs[tab];

      buffer.push(
        <Tab
          type="bold"
          isCompleteVisible={false}
          key={index}
          hidden={hidden}
          label={label}
          selected={active}
          onClick={this.activate.bind(this, tab)} />
        )
        index++;
    }
    return buffer;
  }

  /*decision(decision) {
    let event = { ...this.props.event.event, approved: decision };

    this.props.postEventDecision(event);
  }*/

  getMenu() {
    return (
      <Menu>
        <Menu.Item key="0">
          <div className="button-text-bold" style={{textAlign: 'center'}} onClick={this.postEventDecision.bind(this, true)}>APPROVE EVENT</div>
        </Menu.Item>
        <Menu.Item key="1">
          <div className="button-text-bold" style={{textAlign: 'center'}} onClick={this.toggleRequestEventUpdateModal.bind(this, true)}>REQUEST EVENT UPDATE</div>
        </Menu.Item>
        <Menu.Item key="1">
          <div className="button-text-bold" style={{textAlign: 'center'}} onClick={this.toggleRejectEventModal.bind(this, true)}>REJECT EVENT</div>
        </Menu.Item>
      </Menu>
    );
  }

  postEventDecision(decision) {
    if (this.props.event.event.id === this.props.decision.event_id || this.props.event.event.accepted !== null) {
      notification.info({
        message: `Decision has already been made.`
      });
      return;
    }
    this.props.postEventDecision(
      this.props.event.event.id,
      decision
    );
  }

  render() {
    if (this.props.event.name === "") return null;
    let { event, location, audienceAttributes, packages, assets, organization } = this.props.event;
    let { name, startdate, enddate, tagline, website } = event;

    if (!location) return null;
    let { description} = location;
    let images = this.getImages(assets);
    let logos = this.getLogo(assets);

    let logoElement = <img alt="Event Logo" style={{height: '175px'}} src={NoEventLogo} />

    if (logos.length > 0) {
      logoElement = <img alt="Event Logo" style={{width: '80%'}} src={logos[0].src} />
    }

    return (
      <div className="container" style={{paddingBottom: '30px'}}>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px', marginTop: '30px'}} header={`Event Review: ${this.props.event.event.name}`} />
          <div>
            <Dropdown overlay={this.getMenu()} trigger={['click']}>
              <Button
                iconOrientation="reverse"
                icon={DownArrow}
                iconStyles={{height: '9px'}}
                label="Decision"
                type="tertiary"
                onClick={(()=>{})}
                stopPropagation={true} />
            </Dropdown>
        </div>
        </div>
        <Divider style={{ marginBottom: '25px'}}/>
        <div className="d-flex flex-md-row flex-column">
          <EventSidePanel
            location={location}
            logos={logos}
            website={website}
            tagline={tagline}
            name={name}
            startdate={startdate}
            enddate={enddate}
            assets={assets}
            venuename={location.name}
            venueaddress={location.formatted_address}
            organization={organization} />
          <div className="event-main-panel col-md-8 col-12 event-details-main flex-shrink-0 d-flex flex-column">
            <EventGallery images={images}/>
            <div className="d-flex flex-column event-tab-group">
              <div>
                <TabGroup>
                  {this.getTabs()}
                </TabGroup>
              </div>
              <div style={{height: '100%'}}>
                { this.setOverviewTab(event.description) }
                { this.setPackagesTab(packages) }
                { this.setAudienceTab(audienceAttributes)}
              </div>
            </div>
          </div>
        </div>
        <Modal
          appendFooter={() => {
            return(
              <ButtonGroup
                labelPrimary="SUBMIT"
                labelSecondary="CANCEL"
                onClickPrimary={this.onClickSubmitRequestEventUpdate.bind(this)}
                onClickSecondary={this.toggleRequestEventUpdateModal.bind(this, false)} />
            )
          }}
          closeColor="#BDBDBD"
          onClickClose={this.toggleRequestEventUpdateModal.bind(this, true)}
          visible={this.state.isEventRequestUpdateModalVisible}>
          <div className="admin-review-container">
            <Detail
              style={{marginBottom: '25px'}}
              justification="start"
              header="Request an event page update"
              subHeader="Enter a message to the event organizer below to request an event update." />

            <Divider style={{marginBottom: '15px'}} type="brand" />
            <div>
              <TextField
                fontStyle="tertiary"
                name="message"
                value={this.state.message.value}
                error={this.state.message.error}
                isTextArea={true}
                onChange={this.onChange.bind(this)}
                label="Message"
                size="full" />
            </div>
          </div>
        </Modal>
        <Modal
          appendFooter={() => {
            return(
              <ButtonGroup
                labelPrimary="SUBMIT"
                labelSecondary="CANCEL"
                onClickPrimary={this.onClickSubmitRequestEventUpdate.bind(this)}
                onClickSecondary={this.toggleRejectEventModal.bind(this, false)} />
            )
          }}
          closeColor="#BDBDBD"
          onClickClose={this.toggleRejectEventModal.bind(this, false)}
          visible={this.state.isRejectEventModalVisible}>
          <div className="admin-review-container">
            <Detail
              style={{marginBottom: '25px'}}
              justification="start"
              header="Reject event"
              subHeader="Enter a message to the event organizer below to explain the reason for rejection." />
            <div >
              <Divider style={{marginBottom: '15px'}} type="brand" />
              <div>
                <TextField
                  fontStyle="tertiary"
                  name="message"
                  isTextArea={true}
                  value={this.state.message.value}
                  error={this.state.message.error}
                  onChange={this.onChange.bind(this)}
                  label="Message"
                  size="full" />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.events.currentEvent,
    items: state.cart.items,
    decision: state.events.decision
  };
};


const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getEvent,
      postEventDecision
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminEventReview);
