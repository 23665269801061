import { ActionTypes } from './auth.actions';

const initialState = {
    errorMessage: "",
    error: false,
    loggedOut: false,
    success: null
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FACEBOOK_LOGIN_SUCCESS:
        case ActionTypes.USER_LOGIN_SUCCESS:
            return { ...state, errorMessage: "", error: false, success: true, user: action.data };
        case ActionTypes.USER_LOGIN_FAILURE:
            return { ...state, errorMessage: action.errorMessage, success: false, error: true };
        case ActionTypes.USER_LOGOUT_SUCCESS:
            return { ...state, loggedOut: true };
        case ActionTypes.FACEBOOK_LOGIN_FAILURE:
        default:
            return state;
    }
};

export default AuthReducer;
