import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import PaginatedButtonGroup from 'components/PaginatedButtonGroup.component';
import { generateNumberFormat } from 'common/helpers/number';
import { Table } from 'antd';
import { searchConferenceProspectuses } from 'services/conferenceprospectuses/conferenceprospectuses.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'services/Api'
import './pdfs.css';

const columns = [
  {
    title: 'URL',
    dataIndex: 'conference_prospectus_download_url',
    width: 200,
    fixed: 'left',
    key: 'url'
  },
  {
    title: 'Title',
    dataIndex: 'conference_prospectus_download_url',
    width: 200,
    fixed: 'left',
    key: 'title'
  },
  {
    title: 'Snippet',
    width: 200,
    dataIndex: 'conference_prospectus_download_snippet',
    key: 'snippet'
  },
  {
    title: 'Search Term',
    width: 200,
    dataIndex: 'conference_prospectus_download_search_term',
    key: 'searchterm'
  },
  {
    title: 'S3 PDF URL',
    width: 200,
    dataIndex: 'conference_prospectus_download_s3PDFURL',
    key: 's3PDFURL'
  }

];

class PDFs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      term: {
        value: "Las Vegas Convention Center",
        error: false
      },
      prevSearchTerm: "Las Vegas Convention Center",
      offset: 0
    }
  }

  componentDidMount() {
    this.props.searchConferenceProspectuses(this.state.term.value, this.state.offset);
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  search() {
    if (this.state.term.value.length === 0) {
      this.setState({
        term: {
          value: "",
          error: true,
          errorMessage: "Search term cannot be empty."
        }
      });
      return;
    }
    let offset = this.state.offset;
    if (this.state.prevSearchTerm !== this.state.term.value) {
      offset = 0;
    }

    this.setState({
      prevSearchTerm: this.state.term.value,
      offset
    }, () => {
      this.props.searchConferenceProspectuses(this.state.term.value, this.state.offset);
    })
  }

  next() {
    this.setState({
      offset: this.state.offset + 10
    }, () => {
      this.search();
    })
  }

  back() {
    this.setState({
      offset: this.state.offset - 10
    }, () => {
      this.search();
    })
  }

  action(offset) {
    this.setState({
      offset
    }, () => {
      this.search();
    });
  }

  render() {
    let { term } = this.state;
    let { prospectuses, total } = this.props;

    return (
      <Wrapper className="container">
      <div>
        <div className="d-flex flex-md-row flex-column justify-content-between">
          <Detail justification="start" header="Document Repository" subHeader="Document Repository" />
          <div className="pdf-search-group">
            <FormGroup>
              <TextField
                size="full"
                name="term"
                value={term.value}
                error={term.error}
                errorMessage={term.errorMessage}
                placeholder='Search'
                onChange={this.onChange.bind(this)} />
              <Button
                type="primary"
                label="Search"
                onClick={this.search.bind(this)} />
            </FormGroup>
          </div>
        </div>
        <div className="body-copy-tertiary" style={{marginTop: '10px'}}>Total Documents: {generateNumberFormat(total)}</div>
      </div>
        <div style={{marginTop: '25px'}}>
          {/*
            <Eyebrow label="PDFs" />
            <Table dataSource={prospectuses} columns={columns} />
          */}
          <div>
          {
            prospectuses.map((prospectus) => {
              let {
                conference_prospectus_download_url,
                conference_prospectus_download_snippet
              } = prospectus;
              return (
                <div className="restage-pdf-section d-flex flex-column justify-content-center">
                  <a className="restage-pdf-link body-copy-tertiary-link" href={conference_prospectus_download_url} target="_blank" rel="noopener noreferrer">{conference_prospectus_download_url}</a>
                  <div className="body-copy-tertiary">{conference_prospectus_download_snippet}</div>
                </div>
              )
            })
          }
          </div>
          <div className='d-flex' style={{marginTop: '50px'}}>
            <PaginatedButtonGroup
              total={this.props.total}
              offset={this.state.offset}
              next={this.next.bind(this)}
              back={this.back.bind(this)}
              action={this.action.bind(this)} />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    prospectuses: state.conferenceprospectuses.prospectuses,
    total: state.conferenceprospectuses.total,
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      searchConferenceProspectuses
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(PDFs);
