import React, { Component } from 'react';
import TextField from 'components/TextField';
import Calendar from 'components/Calendar';
import Places from 'common/Places';
import Detail from 'common/Detail';
import Form from 'components/Form';
import ButtonGroup from 'components/ButtonGroup';
import FormGroup from 'components/FormGroup';
import Upload from 'components/Upload';
import SidePanel from './sidepanel.component';
import { notification } from 'antd';
import moment from 'moment';
import { isFilled, isURL, isNumeric } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';

import './eventdetails.css';

const AssetTypes = {
  photos: 1,
  video: 2,
  logo: 3,
  prospectus: 4
}

class EventDetails extends Component {
  static results = []

  constructor(props) {
    super(props);

    this.state = {
      name: {
        value: props.name,
        error: false
      },
      startdate: {
        value: props.startdate,
        error: false
      },
      enddate: {
        value: props.enddate,
        error: false
      },
      description: {
        value: props.description,
        error: false
      },
      place_id: {
        value: props.place_id,
        error: false
      },
      place_name: {
        value: props.place_name
      },
      tagline: {
        value: props.tagline,
        error: false
      },
      website: {
        value: props.website,
        error: false
      },
      expected_attendees: {
        value: props.expected_attendees,
        error: false
      },

      twitter_url: {
        value: props.twitter_url,
        error: false
      },
      facebook_url: {
        value: props.facebook_url,
        error: false
      },
      instagram_url: {
        value: props.instagram_url,
        error: false
      },
      youtube_url: {
        value: props.youtube_url,
        error: false
      },
      assets: {
        error: false
      },

      user_id: "",
      assets: []
    }
  }

  addSidePanel() {
    return (<SidePanel assets={this.props.assets} />);
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.location.pathname === this.props.location.pathname) && (this.props.event !== prevProps.event)) {
        this.props.history.push(Routes.EVENT_AUDIENCE);
        return;
    }

    if (this.props.event !== prevProps.event) {
      let { event, location } = this.props.event;
      let { name, startdate, enddate, description, tagline, website, expected_attendees,
      facebook_url, instagram_url, twitter_url, youtube_url } = event;
      let { google_place_id } = location;

      this.setState({
        name: {
          value: name,
          error: false
        },
        startdate: {
          value: startdate,
          error: false
        },
        enddate: {
          value: enddate,
          error: false
        },
        description: {
          value: description,
          error: false
        },
        place_id: {
          value: google_place_id,
          error: false
        },
        location: location || {},
        place_name: {
          value: location.name,
        },
        tagline: {
          value: tagline,
          error: false
        },
        expected_attendees: {
          value: expected_attendees,
          error: false
        },
        website: {
          value: website,
          error: false
        }
        ,twitter_url: {
          value: twitter_url,
          error: false
        }
        ,instagram_url: {
          value: instagram_url,
          error: false
        }
        ,facebook_url: {
          value: facebook_url,
          error: false
        }
        ,youtube_url: {
          value: youtube_url,
          error: false
        }

      })
    }
  }

  componentDidMount() {
    if (this.props.event) {
      let { name, description, startdate, enddate, tagline, website, expected_attendees, location,
      twitter_url, instagram_url, facebook_url, youtube_url } = this.props.event.event;
      this.setState({
        name: {
          value: name,
          error: false
        }, place_id: {
          value: location.google_place_id,
          place_name: location.description,
          error: false
        }, startdate: {
          value: startdate,
          error: false
        }, place_name: {
          value: location.description
        }, enddate: {
          value: enddate,
          error: false
        }, description: {
          value: description,
          error: false
        }, tagline: {
          value: tagline,
          error: false
        }, expected_attendees: {
          value: expected_attendees,
          error: false
        }, website: {
          value: website,
          error: false
        }, twitter_url: {
          value: twitter_url,
          error: false
        }, instagram_url: {
          value: instagram_url,
          error: false
        }, facebook_url: {
          value: facebook_url,
          error: false
        } ,youtube_url: {
          value: youtube_url,
          error: false
        }
      });
    }
  }

  _handleOnAutocompleteOptionSelection(selected) {
    let place = selected[0];
    this.setState({
      place_id: {
        value: place.placeId,
        error: false
      },
      place_name: {
        value: place.description
      }
    });
  }

  onChangeDate(key, value) {
    this.setState({
      [key]: {
        value,
        error: false
      },
    }, () => {
      if (key === "startdate" && this.state.enddate.value !== null && this.state.startdate.value > this.state.enddate.value) {
        this.setState({
          enddate: {
            value: this.state.startdate.value,
            error: false
          }
        })
      }
    });
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  validate() {
    let fields = new Set();
    fields.add(this.isFieldValid(isFilled, "name"));
    fields.add(this.isFieldValid(isFilled, "description"));
    fields.add(this.isFieldValid(isFilled, "startdate"));
    fields.add(this.isFieldValid(isFilled, "enddate"));
    fields.add(this.isFieldValid(isFilled, "place_id"));
    fields.add(this.isFieldValid(isFilled, "tagline"));
    fields.add(this.isFieldValid(isURL, "website"));

    let assetsValid = (this.props.assets.length > 0);
    if (!assetsValid) {
      fields.add(false)
    }

    this.setState({
      assets: {
        error: !assetsValid
      }
    })
/*
    fields.add(this.isFieldValid(isURL, "twitter_url"));
    fields.add(this.isFieldValid(isURL, "facebook_url"));
    fields.add(this.isFieldValid(isURL, "instagram_url"));
    fields.add(this.isFieldValid(isURL, "youtube_url"));*/

    fields.add(this.isFieldValid(isNumeric, "expected_attendees"))

    return !fields.has(false);
  }

  isFieldValid(validator, key) {
    let isValid = true;

    if (key === "expected_attendees") {
      isValid = validator(this.state[key].value + "");
    } else {
      isValid = validator(this.state[key].value);
    }

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }

  buildUrl(url) {
    if (!url.includes("http")) {
      return `http://${url}`;
    }
    return url;
  }

  onClickSubmitEventDetails() {
    let isValid = this.validate();

    this.props.setEventDetailsPage(isValid);
    if (!isValid) return;

    let { name, place_id, startdate, enddate, description, place_name, tagline, website, expected_attendees,
    twitter_url, facebook_url, instagram_url, youtube_url } = this.state;

    let data = {};

    if (this.props.event.event.id) {
      data = {
        ...this.props.event.event,
        place_id: place_id.value,
        name: name.value,
        description: description.value,
        startdate: startdate.value,
        enddate: enddate.value,
        place_name: place_name.value,
        tagline: tagline.value,
        website: this.buildUrl(website.value.toLowerCase()),

        /*twitter_url: this.buildUrl(twitter_url.value.toLowerCase()),
        facebook_url: this.buildUrl(facebook_url.value.toLowerCase()),
        instagram_url: this.buildUrl(instagram_url.value.toLowerCase()),
        youtube_url: this.buildUrl(youtube_url.value.toLowerCase()),*/

        expected_attendees: expected_attendees.value,
        user_id: this.props.user.id
      }

      this.props.putEvent(data);//if event has id then post else put?
    } else {
      data = {
        assets: this.props.assets,
        place_id: place_id.value,
        name: name.value,
        description: description.value,
        startdate: startdate.value,
        enddate: enddate.value,
        place_name: place_name.value,
        tagline: tagline.value,
        website: this.buildUrl(website.value.toLowerCase()),
/*
        twitter_url: this.buildUrl(twitter_url.value.toLowerCase()),
        facebook_url: this.buildUrl(facebook_url.value.toLowerCase()),
        instagram_url: this.buildUrl(instagram_url.value.toLowerCase()),
        youtube_url: this.buildUrl(youtube_url.value.toLowerCase()),
*/
        expected_attendees: expected_attendees.value,
        user_id: this.props.user.id
      }

      this.props.postEvent(data);//if event has id then post else put?
    }
  }

  goToEventHome() {
    this.props.history.push(Routes.ORGANIZER_HOME);
  }

  addAsset(files, asset_type_id) {
    let assets = [];

    Array.from(files).forEach((file) => {
      const data = new FormData();
      data.append('file', file);
      data.append('filename', file.name);
      data.append("asset_type_id", asset_type_id);
      assets.push(data);
    });

    assets.forEach((asset) => {
      this.props.addEventAsset(asset);
    });

    this.addSidePanel(()=>{
      return (<SidePanel assets={this.props.assets} />);
    });
  }

  handleSubmit(event, files) {
    this.addAsset(files, AssetTypes.photos);
  }


  handleSubmitPDF(event, files) {
    this.addAsset(files, AssetTypes.prospectus);
  }

  handleSubmitVideo(event, files) {
    let videoList = this.props.assets.filter((asset) => AssetTypes.video == asset.get("asset_type_id"));

    if (videoList.length > 0) {
      notification.info({
        message: `Only one video can be added.`
      });
      return;
    }

    this.addAsset(files, AssetTypes.video);
  }

  handleSubmitLogo(event, files) {
    let logoList = this.props.assets.filter((asset) => AssetTypes.logo == asset.get("asset_type_id"));

    if (logoList.length > 0) {
      notification.info({
        message: `Only one logo can be added.`
      });
      return;
    }

    this.addAsset(files, AssetTypes.logo);
  }

  disabledDate(current) {
    return current && current < moment().endOf('day');
  }

  render() {
    let { place_id, name, description, startdate, enddate, place_name, tagline, website, expected_attendees,
    twitter_url, instagram_url, facebook_url, youtube_url, assets } = this.state;

    let location = {};

    if (this.props.event.location) {
      location = this.props.event.location;
    }

    let place_description = (location.description ? location.description : null)

    return (
      <div>
        <Form className="eventdetails-container" addSidePanel={this.addSidePanel.bind(this)}>
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Details" subHeader="Step 1 of 4" />
          <TextField
            fontStyle="tertiary"
            name="name"
            placeholder="Event name"
            value={name.value}
            errorMessage="Event name cannot be empty."
            error={name.error}
            onChange={this.onChange.bind(this)}
            label="Event Name"
            size="full" />
          <Places
            isGeopoliticalRegionAllowed={false}
            fontStyle="tertiary"
            label="Venue"
            name="venue"
            value={place_name.value ? place_name.value : place_description}
            placeholder="Event venue"
            errorMessage="Please select the venue of the event."
            error={place_id.error}
            onOptionSelection={this._handleOnAutocompleteOptionSelection.bind(this)} />
          <FormGroup>
            <Calendar
              timeFormat={false}
              fontStyle="tertiary"
              value={(startdate.value ? new Date(startdate.value) : null)}
              disabledDate={this.disabledDate.bind(this)}
              placeholder="Start date and time"
              errorMessage="Start date cannot be empty."
              error={startdate.error}
              onChange={this.onChangeDate.bind(this, "startdate")}
              label="Start Date"
              size="full"/>
            <Calendar
              timeFormat={false}
              fontStyle="tertiary"
              placeholder="End date and time"
              isValidDate={(current) => current.isAfter(this.state.startdate.value || new Date())}
              value={(enddate.value ? new Date(enddate.value) : null)}
              disabledDate={this.disabledDate.bind(this)}
              errorMessage="End date cannot be empty."
              error={enddate.error}
              onChange={this.onChangeDate.bind(this, "enddate")}
              label="End Date"
              size="full"/>
          </FormGroup>
          <TextField
            fontStyle="tertiary"
            name="website"
            placeholder="Enter the event website"
            value={website.value}
            errorMessage="Please enter a valid URL."
            error={website.error}
            onChange={this.onChange.bind(this)}
            label="Event Website"
            size="full" />
          {/*
          <TextField
            fontStyle="tertiary"
            name="twitter_url"
            placeholder="Enter twitter handle"
            value={twitter_url.value}
            errorMessage="Please enter a valid URL."
            error={twitter_url.error}
            onChange={this.onChange.bind(this)}
            label="Event Twitter URL (Optional)"
            size="full" />
          <TextField
            fontStyle="tertiary"
            name="facebook_url"
            placeholder="Enter the event facebook page"
            value={facebook_url.value}
            errorMessage="Please enter a valid URL."
            error={facebook_url.error}
            onChange={this.onChange.bind(this)}
            label="Event Facebook URL (Optional)"
            size="full" />
          <TextField
            fontStyle="tertiary"
            name="youtube_url"
            placeholder="Enter the event youtube page"
            value={youtube_url.value}
            errorMessage="Please enter a valid URL."
            error={youtube_url.error}
            onChange={this.onChange.bind(this)}
            label="Event Youtube URL (Optional)"
            size="full" />
          <TextField
            fontStyle="tertiary"
            name="instagram_url"
            placeholder="Enter the event instagram page"
            value={instagram_url.value}
            errorMessage="Please enter a valid URL."
            error={instagram_url.error}
            onChange={this.onChange.bind(this)}
            label="Event Instagram URL (Optional)"
            size="full" />*/}

          <TextField
            fontStyle="tertiary"
            name="tagline"
            placeholder="Enter a one sentence event description"
            value={tagline.value}
            errorMessage="Event name cannot be empty."
            error={tagline.error}
            onChange={this.onChange.bind(this)}
            label="Event Tagline"
            size="full" />
          <TextField
            fontStyle="tertiary"
            name="description"
            value={description.value}
            errorMessage="Description cannot be empty."
            error={description.error}
            isTextArea={true}
            onChange={this.onChange.bind(this)}
            label="Event Description"
            placeholder="Event description"
            size="full" />
          <TextField
            fontStyle="tertiary"
            name="expected_attendees"
            integer={true}
            placeholder="Number of expected attendees"
            value={expected_attendees.value}
            errorMessage="Enter number of expected attendees."
            error={expected_attendees.error}
            onChange={this.onChange.bind(this)}
            label="Number of Expected Attendees"
            size="full" />
          <div className="eventdetails-upload">
            {/*<Upload multiple={false} label="Event Video (Optional)" onSubmit={this.handleSubmitVideo.bind(this)} />*/}
            <Upload
              accept="image/*"
              multiple={false}
              label="Event Logo (Optional)"
              onSubmit={this.handleSubmitLogo.bind(this)} />
            {/*<Upload multiple={false} label="Conference Prospectus (Optional)" onSubmit={this.handleSubmitPDF.bind(this)} />*/}
            <Upload
              accept="image/*"
              multiple={true}
              error={assets.error}
              errorMessage="Please upload at least one photo."
              label="Event Pictures"
              onSubmit={this.handleSubmit.bind(this)} />
          </div>
        </Form>
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 1 of 4</div>
          <ButtonGroup
            labelPrimary="NEXT"
            labelSecondary="BACK"
            onClickPrimary={this.onClickSubmitEventDetails.bind(this)}
            onClickSecondary={this.goToEventHome.bind(this)} />
        </div>
      </div>
    );
  }
}

export default EventDetails;
