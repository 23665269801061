import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './conferenceprospectuses.actions';
import { post } from '../Api'

const basePath = "conference-prospectuses";

export const search = function* (action) {
  const { payload: { term, offset} } = action;
  try {
    const response = yield call(post, `${basePath}/search?term=${term}&offset=${offset}`);
    const json = yield response.json();
    console.log(json, "jason")
    yield put({ type: ActionTypes.SEARCH_CONFERENCE_PROSPECTUSES_SUCCESS, prospectuses: json.conferenceProspectuses, total: json.total });
  } catch (error) {
    yield put({ type: ActionTypes.SEARCH_CONFERENCE_PROSPECTUSES_FAILURE, error });
  }
}

function* conferenceprospectuses() {
  yield all([
    takeLatest(ActionTypes.SEARCH_CONFERENCE_PROSPECTUSES, search)
  ]);
}

export default conferenceprospectuses;
