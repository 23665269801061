import { all } from 'redux-saga/effects';
import auth from 'services/auth/auth.sagas';
import blog from 'services/blog/blog.sagas';
import events from './services/events/events.sagas';
import eventsponsors from './services/eventsponsors/eventsponsors.sagas';
import users from './services/users/users.sagas';
import places from './services/places/places.sagas';
import signUpSagas from './services/signup/signup.sagas';
import sponsorships from './services/sponsorships/sponsorships.sagas';
import eventassets from './services/eventassets/eventassets.sagas';
import sponsorshiprequests from './services/sponsorshiprequests/sponsorshiprequests.sagas';
import sponsornewslettersignup from './services/sponsornewslettersignup/sponsornewslettersignup.sagas';
import newslettersignup from './services/newslettersignup/newslettersignup.sagas';
import tap from './services/tap/tap.sagas';
import messages from './services/messages/messages.sagas';
import portfolios from './services/portfolios/portfolios.sagas';
import portfolioobjectives from './services/portfolioobjectives/portfolioobjectives.sagas';
import portfolioaudienceprofile from './services/portfolioaudienceprofile/portfolioaudienceprofile.sagas';
import portfolioplacementpreferences from './services/portfolioplacementpreferences/portfolioplacementpreferences.sagas';
import eventaudienceprofile from './services/eventaudienceprofile/eventaudienceprofile.sagas';
import portfoliodesiredplacements from './services/portfoliodesiredplacements/portfoliodesiredplacements.sagas';
import eventsponsorshipoffers from './services/eventsponsorshipoffer/eventsponsorshipoffer.sagas';
import eventpackageoffer from './services/eventpackageoffer/eventpackageoffer.sagas';
import forgotpassword from './services/forgotpassword/forgotpassword.sagas';
import occupations from './services/occupations/occupations.sagas';
import industries from './services/industries/industries.sagas';
import placements from './services/placements/placements.sagas';
import packages from './services/packages/packages.sagas';
import verify from './services/verify/verify.sagas';
import availableplacements from './services/availableplacements/availableplacements.sagas';
import packagecategories from './services/packagecategories/packagecategories.sagas';
import packagetypes from './services/packagetypes/packagetypes.sagas';
import placementtypes from './services/placementtypes/placementtypes.sagas';
import jobseniorities from './services/jobseniorities/jobseniorities.sagas';
import jobfunctions from './services/jobfunctions/jobfunctions.sagas';
import negotiations from './services/negotiations/negotiations.sagas';
import eventattendances from './services/eventattendances/eventattendances.sagas';
import eventgenders from './services/eventgenders/eventgenders.sagas';
import conferenceprospectuses from './services/conferenceprospectuses/conferenceprospectuses.sagas';
import shares from './services/shares/shares.sagas';
import firebase from './firebase/firebase.sagas';

export default function* rootSaga() {
  yield all([
    auth(),
    availableplacements(),
    blog(),
    events(),
    eventsponsors(),
    eventassets(),
    firebase(),
    users(),
    signUpSagas(),
    places(),
    sponsorships(),
    sponsorshiprequests(),
    tap(),
    messages(),
    portfolios(),
    eventpackageoffer(),
    packagecategories(),
    packagetypes(),
    placementtypes(),
    newslettersignup(),
    portfolioaudienceprofile(),
    eventaudienceprofile(),
    sponsornewslettersignup(),
    portfoliodesiredplacements(),
    portfolioplacementpreferences(),
    eventsponsorshipoffers(),
    forgotpassword(),
    occupations(),
    negotiations(),
    industries(),
    jobseniorities(),
    eventattendances(),
    eventgenders(),
    jobfunctions(),
    packages(),
    portfolioobjectives(),
    placements(),
    verify(),
    shares(),
    conferenceprospectuses()
  ]);
}
