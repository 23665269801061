import { ActionTypes } from './conferenceprospectuses.actions';

const initialState = {
    prospectuses: [],
    total: 0
};

const ConferenceProspectusesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SEARCH_CONFERENCE_PROSPECTUSES_SUCCESS:
        return { ...state, prospectuses: action.prospectuses, total: action.total };
      case ActionTypes.SEARCH_CONFERENCE_PROSPECTUSES_FAILURE:
        return { ...state, error: true, errorMessage: "" };
      default:
          return state;
    }
};

export default ConferenceProspectusesReducer;
